export type Category = 'Account' | 'Newsletter'

const baseEvent = {
  event: 'loadEventData',
  nonInteractive: false,
  customMetrics: {},
  customVariables: {},
  _clear: true
}

export default {
  onImpression: (eventCategory: Category, eventAction?: string) => ({
    ...baseEvent,
    eventAction,
    eventCategory,
    eventLabel: 'Popup_Impression',
    nonInteractive: true
  }),
  onExpand: (eventCategory: Category, eventAction?: string) => ({
    ...baseEvent,
    eventAction,
    eventCategory,
    eventLabel: 'Popup_Expand'
  }),
  onCollapse: (eventCategory: Category, eventAction?: string) => ({
    ...baseEvent,
    eventAction,
    eventCategory,
    eventLabel: 'Popup_Collapse'
  }),
  onClose: (eventCategory: Category, eventAction?: string) => ({
    ...baseEvent,
    eventAction,
    eventCategory,
    eventLabel: 'Popup_Close'
  }),
  onClick: (eventCategory: Category, eventAction?: string, label?: string) => ({
    ...baseEvent,
    eventAction,
    eventCategory,
    eventLabel: `Popup_Click on ${label}`
  })
}
