const queryParamsToPreserve = [
  'siteId',
  'cms',
  'preview',
  'enrollment_marketing_source'
]

/**
 * This middleware is used to preserve content query parameters when navigating between pages.
 * siteId - The ID of the site to fetch content from (timberland-en-150, timberland-en-us, timberland-showcase-en-us, etc.)
 * cms - The CMS environment to fetch content from (dev, qa, uat, prod). Handled by Akamai
 * preview - A flag to enable preview mode.
 * enrollment_marketing_source - Passing campaignName for promotions tracking in OCP
 */
export default defineNuxtRouteMiddleware((to, from) => {
  const toPreserve = queryParamsToPreserve
    .filter((name) => from.query[name] && !to.query[name])
    .map((name) => [name, from.query[name]])

  if (!toPreserve.length) return

  return navigateTo({
    ...to,
    query: {
      ...to.query,
      ...Object.fromEntries(toPreserve)
    }
  })
})
