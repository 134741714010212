const baseEvent = {
  event: 'loadEventData',
  eventCategory: 'STL',
  nonInteractive: false,
  customMetrics: {},
  customVariables: {},
  _clear: true
}

export default {
  onClickOpenModal: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Open Modal Click',
    eventLabel
  }),
  onSelectSize: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Select Size',
    eventLabel
  }),
  onClickCloseModal: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Close Modal Click',
    eventLabel
  }),
  onClickShowFullOutfit: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Show Full Outfit Click',
    eventLabel
  })
}
