// https://digital.vfc.com/wiki/display/ECOM15/Videos
const getBaseEvent = ({ currentTime, duration }: HTMLMediaElement, label = '') => ({
  event: 'loadEventData',
  eventCategory: 'Video',
  eventLabel: label,
  customMetrics: {
    videoDuration: Math.round(duration),
    videoCurrentTime: Math.round(currentTime)
  },
  nonInteractive: true,
  _clear: true
})

export default {
  onPlay: (target, label) => ({
    ...getBaseEvent(target, label),
    eventAction: Math.round(target.currentTime) === 0 ? 'Start' : 'Play'
  }),
  onPause: (target, label) => ({
    ...getBaseEvent(target, label),
    eventAction: 'Pause'
  }),
  onComplete: (target, label) => ({
    ...getBaseEvent(target, label),
    eventAction: 'Complete'
  })

}
