const baseEvent = {
  event: 'loadEventData',
  eventCategory: 'PDP',
  nonInteractive: false,
  customMetrics: {},
  customVariables: {},
  _clear: true
}

export default {
  onUpsellImpression: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Cross-sell_Impressions',
    eventLabel
  }),
  onUpsellSelect: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Cross-sell_Add Click',
    eventLabel
  }),
  onUpsellDeselect: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Cross-sell_Remove Click',
    eventLabel
  }),
  onUpsellDetails: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Cross-sell_Details Click',
    eventLabel
  })
}
