const baseEvent = {
  eventCategory: 'Enhanced Ecommerce',
  eventLabel: undefined,
  nonInteractive: true,
  _clear: true,
  customMetrics: {},
  customVariables: {}
}

const baseLoadEvent = {
  event: 'loadEventData',
  eventCategory: 'Payment',
  eventAction: 'Method Selection',
  eventLabel: undefined,
  nonInteractive: 0,
  customMetrics: {},
  customVariables: {}
}

type Step = 'cart' | 'shipping' | 'billing' | 'summary'

type CheckoutEventActions =
  'Checkout - Payment' |
  'Checkout - Cart' |
  'Checkout Option' |
  'Order Confirmation' |
  'Checkout - Shipping' |
  'Checkout - Summary'

const eventActions: Record<Step, CheckoutEventActions> = {
  cart: 'Checkout - Cart',
  shipping: 'Checkout - Shipping',
  billing: 'Checkout - Payment',
  summary: 'Checkout - Summary'
}

const CheckoutStepNumber = {
  cartEmpty: 0,
  cart: 1,
  shipping: 2,
  billing: 3,
  summary: 4
}

const getOrderPromoActionFields = (cart) => {
  let totalPrice = 0
  const promoCodes: string[] = []
  const promoTypes: string[] = []

  cart?.orderPromotions?.forEach((promo) => {
    totalPrice += Math.abs(promo.price)
    promoCodes.push(promo.couponCode || promo.itemText)
    promoTypes.push(promo.couponCode ? 'CODE' : 'AUTO')
  })

  return {
    orderPromoAmount: totalPrice,
    orderPromoCode: promoCodes.length ? promoCodes.join(',') : undefined,
    orderPromoType: promoTypes.length ? promoTypes.join(',') : undefined
  }
}

const getShippingActionFields = (shipment) => {
  const shippingPromoCode = shipment.shippingPromotions?.find((promo) => !!promo.couponCode)?.couponCode
  let shippingPromoType: string | undefined
  if (shipment.shippingPromotions?.length)
    shippingPromoType = shippingPromoCode ? 'CODE' : 'AUTO'

  return {
    shippingMethod: (shipment?.shippingMethod?.code || shipment.code)?.toUpperCase(),
    shippingPromoCode,
    shippingPromoType,
    shippingPromoAmount: (shipment.shippingPromotions || [])
      .reduce((amount, promo) => promo.appliedDiscount?.amount ? amount + Math.abs(promo.price) : amount, 0)
  }
}

const getActionField = (cart, step, option) => ({
  step: cart.items.length ? CheckoutStepNumber[step] : CheckoutStepNumber.cartEmpty,
  option: option || ((['cart', 'shipping'].includes(step?.toLowerCase())
    ? `Shipping Option: ${cart.shippingMethods[0].label}`
    : undefined)),
  ...getOrderPromoActionFields(cart),
  ...(cart.shippingMethods?.[0] ? getShippingActionFields(cart.shippingMethods?.[0]) : {})
})

const getGiftCardActionFields = (order) => {
  const giftCardPayment = order?.paymentMethod.find((type) => type.code === 'GIFT_CARD')
  return {
    giftCard: !!giftCardPayment,
    giftCardAmount: giftCardPayment ? giftCardPayment.additionalData?.amount : 0
  }
}

const getOrderActionFields = (order) => ({
  affiliation: useSiteId(),
  coom_01: order?.billingAddress?.email,
  id: order?.orderNumber,
  paymentType: order?.paymentMethod.map((type) => type?.code).join(', '),
  revenue: order?.totals?.totalWithoutTaxAfterDiscount,
  rewardsAmount: order?.paymentMethod.find(({ code }) => code === 'LOYALTY_POINTS')?.additionalData?.amount || 0,
  shipping: order?.totals?.shipping,
  tax: order?.totals?.merchandiseTotalTax,
  total: order?.totals?.total
})

export default {
  onCheckout: (cart, step: Step, option?) => ({
    ...baseEvent,
    event: 'checkout',
    eventAction: eventActions[step],
    ecommerce: {
      currencyCode: cart.currency,
      checkout: {
        actionField: getActionField(cart, step, option),
        products: getProductObjectList(cart.items, {
          isCartItem: true,
          omitPosition: true
        })
      }
    }
  }),
  onCheckoutCompletion: (order) => ({
    ...baseEvent,
    event: 'checkoutCompletion',
    eventAction: 'Order Confirmation',
    ecommerce: {
      currencyCode: order.totals.currency,
      purchase: {
        actionField: {
          ...getOrderActionFields(order),
          ...getGiftCardActionFields(order),
          ...getOrderPromoActionFields(order),
          ...(order.shipments?.[0] ? getShippingActionFields(order.shipments?.[0]) : {})
        },
        products: getProductObjectList(order.items, {
          isCartItem: true,
          omitPosition: true
        })
      }
    }
  }),
  onCheckoutOption: (cart, step: Step, method) => {
    const option = `${step === 'billing' ? 'Payment' : 'Shipping'} Method: ${method}`

    return {
      ...baseEvent,
      event: 'checkoutOption',
      eventAction: 'Checkout Option',
      eventLabel: option,
      ecommerce: {
        currencyCode: cart.currency,
        checkout_option: {
          actionField: {
            ...getActionField(cart, step, option),
            ...(step === 'billing' ? { paymentType: method } : {})
          }
        }
      }
    }
  },
  onLoadEventData: (option) => ({
    ...baseLoadEvent,
    eventLabel: option
  }),
  onClickDeliveryMethod: (option) => ({
    ...baseLoadEvent,
    eventCategory: 'Delivery',
    eventLabel: option
  })
}
