export default defineNuxtPlugin(() => {
  const { allowForterSessionToken } = useFeatureFlags()
  const { targetEnv } = useRuntimeConfig().public

  if (allowForterSessionToken) {
    useEventListener(document, 'ftr:tokenReady', (evt: any) => {
      useState<string>('forterSessionToken', () => evt.detail)
    })

    // load the forter script
    const region = useRegion()
    const { siteId } = useAppConfig().checkout.forter[region]

    const { $script } = useScript({
      src: `/forter/forter.js`,
      id: siteId[targetEnv],
      async: true,
      crossorigin: 'anonymous',
      tagPosition: 'bodyClose'
    })

    $script.catch((err) => {
      console.warn('failed to load forter script', err)
    })
  }
})
