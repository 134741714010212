// https://digital.vfc.com/wiki/display/ECOM15/PLP+Components

const getFilterValue = (selected) => Array.isArray(selected) ? selected.join('-') : selected

const baseEvent = {
  event: 'loadEventData',
  eventCategory: 'PLP',
  nonInteractive: false,
  customMetrics: {},
  customVariables: {},
  _clear: true
}

export default {
  onApplyFilter: (filterCategory: string, filterId: string, filterValue: string | string[]) => ({
    ...baseEvent,
    eventAction: 'Filter',
    eventLabel: 'Apply',
    customVariables: {
      filterCategory,
      filterId,
      filterValue: getFilterValue(filterValue)
    }
  }),
  onRemoveFilter: (filterCategory: string, filterId: string, filterValue: string) => ({
    ...baseEvent,
    eventAction: 'Filter',
    eventLabel: 'Remove',
    customVariables: {
      filterCategory,
      filterId,
      filterValue: getFilterValue(filterValue)
    }
  }),
  onClearFilters: (filterCategory: string) => ({
    ...baseEvent,
    eventAction: 'Filter',
    eventLabel: 'Clear All',
    customVariables: {
      filterCategory
    }
  }),
  onSort: (eventLabel?: string) => ({
    ...baseEvent,
    eventAction: 'Sort by',
    eventLabel
  }),
  onSelectColor: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Color Click',
    eventLabel
  }),
  onSelectSize: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Select Size',
    eventLabel
  })
}
