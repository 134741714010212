// This plugin creates and manages a device fingerprint used by Cybersource to identify fraud
//
// 1) use UUID to create devices sessionId and set in local storage
// 2) use 'deviceSessionId' to request 'deviceFingerPrintUrl' from MuleSoft API
// 3) store deviceFingerPrintUrl in local storage
// 4) load script using 'deviceFingerPrintUrl' (ex: https://h.online-metrix.net/fp/tags.js?...)
// 5) provide device's sessionId when placing order

declare module '#app' {
  interface NuxtApp {
    $deviceFingerPrint: {
      getSessionId
      load
    }
  }
}

export default defineNuxtPlugin(() => {
  // get/create a uuid for device's session
  const getSessionId = () => {
    return useLocalStorage('deviceSessionId', uuid()).value
  }

  // get/request the deviceFingerPrintUrl
  const getDeviceFingerPrintUrl = async () => {
    let deviceFingerprintURL = localStorage.getItem('deviceFingerPrintUrl')
    if (deviceFingerprintURL && deviceFingerprintURL !== '')
      return deviceFingerprintURL

    try {
      const response = await useApi().cart.$deviceFingerPrint({
        sessionID: getSessionId()
      })
      // capture the deviceFingerprintURL from response, store it and return it
      deviceFingerprintURL = response.data.url
      localStorage.setItem('deviceFingerPrintUrl', deviceFingerprintURL || '')
      return deviceFingerprintURL
    }
    catch (err) {
      console.warn('failed to get getDeviceFingerPrintUrl', err)
    }
  }

  // load the fingerPrint script
  const load = async () => {
    const src = await getDeviceFingerPrintUrl()

    // check if script is already loaded
    if (document.querySelector(`script[src="${src}"]`))
      return

    // add script to head
    try {
      useHead({
        script: [
          {
            src: src || '',
            type: 'text/javascript'
          }
        ]
      }, { mode: 'client' })
    }
    catch (err) {
      console.warn('failed to load deviceFingerPrint script', err)
    }
  }

  return {
    provide: {
      deviceFingerPrint: {
        getSessionId,
        load
      }
    }
  }
})
