export default defineNuxtPlugin(() => {
  const { isDev, isNetlify } = useRuntimeConfig().public
  const { configDynatraceUrl } = useFeatureFlags()

  if (isNetlify) {
  /**
   * On Netlify instance, we can't access to web server and therefore can't install OneAgent,
   * we need to insert the RUM with JavaScript tag format.
   * Doc, https://docs.dynatrace.com/docs/platform-modules/digital-experience/web-applications/initial-setup/rum-injection#manual-insertion.
   */
    if (configDynatraceUrl) {
      const {
        addActionProperties,
        enterAction,
        identifyUser,
        leaveAction,
        sendSessionProperties
      } = useScript<Window['dtrum']>({
        src: configDynatraceUrl,
        crossorigin: 'anonymous'
      }, {
        use: () => window.dtrum
      })

      return {
        provide: {
          dtrum: { addActionProperties, enterAction, identifyUser, leaveAction, sendSessionProperties }
        }
      }
    }
    else if (!isDev) {
      log.info('Dynatrace agentless Real User Monitoring(RUM) script is not injected.')
    }
  }
  else if (import.meta.client) {
  // On AWS instance, RUM script will be injected by OneAgent automatically
    return {
      provide: {
        dtrum: window.dtrum
      }
    }
  }
})
