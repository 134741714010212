import type { MotionVariants } from '@vueuse/motion'
import { MotionDirective } from '@vueuse/motion'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'

export default defineNuxtPlugin(
  (nuxtApp) => {
    const options = useRuntimeConfig().public.motion

    nuxtApp.vueApp.directive('motion', MotionDirective())

    // Register plugin-wise directives
    if (options && options.directives) {
      // Loop on options, create a custom directive for each definition
      for (const key in options.directives) {
        // Get directive variants
        const variants = options.directives[key] as MotionVariants<any>

        // Development warning, showing definitions missing `initial` key
        if (!variants.initial && import.meta.dev)
          console.warn(`Your directive v-motion-${key} is missing initial variant!`)

        // Register the custom `v-motion-${key}` directive
        nuxtApp.vueApp.directive(`motion-${key}`, MotionDirective(variants))
      }
    }
  },
)
