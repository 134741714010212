// https://digital.vfc.com/wiki/display/ECOM15/Topnav+and+Footer
export default {
  onClickFooterLink: (eventLabel: string) => ({
    event: 'loadEventData',
    eventCategory: 'Navigation',
    eventAction: 'Global Footer Nav',
    eventLabel,
    nonInteractive: false,
    customMetrics: {},
    customVariables: {},
    _clear: true
  })
}
