import type { ComputedRef } from 'vue'
import type { RouteLocationNormalized, Router } from 'vue-router'
import type { NuxtApp } from '#app'
import type { CanvasPerformanceMeasure, WebVitalsEvent } from '#types/performance'
import type { LocaleCode } from '#types/locale'

/**
 * Determines the route name to use for performance measurements.
 * This is the current route with the locale removed - there's no
 * point in measuring per locale as the pages are the same structurally
 *
 * @param  routeIn The route to get a name for; falls back to the current route
 * @param  router The router instance to use
 * @param  locales The list of locales to remove from the route name
 * @return The determined route name
 */
function getRouteName(routeIn: RouteLocationNormalized, router: Router, locales: LocaleCode[]) {
  const route = useDelocalisedRoute(routeIn, router, locales) as RouteLocationNormalized
  const routeName = route?.name?.toString() || route?.path

  return routeName?.replace(/[/-]/g, '_')
}

function getRoutePath(routeIn: RouteLocationNormalized, router: Router, locales: LocaleCode[]) {
  const route = useDelocalisedRoute(routeIn, router, locales) as RouteLocationNormalized
  return route?.path
}

/**
 * Generates a performance measurement and triggers its ingestion
 *
 * @param obj
 * @param obj.routeName Included in the name of the Prometheus metric
 * @param obj.routePath Included in the name of the Prometheus metric
 * @param obj.action Included in the name of the Prometheus metric
 */
function measureAction({ action, routeName, routePath }: {
  routeName: string
  routePath?: string
  action: 'render' | 'hydrate'
}) {
  const startMark = `measure:${routeName}:start`

  if (performance.getEntriesByName(startMark).length) {
    const endMark = `measure:${routeName}:end`

    performance.mark(endMark)

    const renderMode = `${import.meta.server ? 'ssr' : 'csr'}`
    const measureName = 'web_performance'
    const labels = {
      action,
      render_mode: renderMode,
      route_name: routeName
    }

    const measure = performance.measure(measureName, startMark, endMark)

    if (routePath)
      log.debug(`Execution time for: ${routePath} - ${Math.round(measure.duration)} ms`)

    if (import.meta.server)
      ingestPerformanceMeasures([{ labels, measure }])
    else
      accumulatePerformanceMeasurement(measure, labels)

    performance.clearMarks(endMark)
    performance.clearMeasures(measureName)
  }

  performance.clearMarks(startMark)
}

function measureServerPerformance({ hook, routeName, routePath }: {
  hook: NuxtApp['hooks']['hook']
  routeName: ComputedRef<string>
  routePath: ComputedRef<string>
}) {
  // On the server we're measuring the time it takes to SSR the pages
  // (i.e. the time it takes to generate the HTML)
  hook('app:created', () => {
    performance.mark(`measure:${routeName.value}:start`)
  })

  hook('app:rendered', () => {
    measureAction({
      action: 'render',
      routeName: routeName.value,
      routePath: routePath.value
    })
  })
}

function measureClientPerformance({
  hook,
  locales,
  routeName,
  measureCsr,
  measureHydration
}: {
  locales: LocaleCode[]
  hook: NuxtApp['hooks']['hook']
  routeName: ComputedRef<string>
  measureCsr: boolean
  measureHydration: boolean
}) {
  // This flags allows us to measure the very first 'render' after SSR
  // in a different Prometheus metric than a full CSR render,
  // in effect tracking only the time it takes the app to hydrate
  let hydrateMeasured = false

  hook('app:mounted', () => {
    if (measureHydration) {
      // This is the beginning of the hydration after SSR;
      // the end point is considered to be page:finish
      performance.mark(`measure:${routeName.value}:start`)
    }

    if (measureCsr) {
      const router = useRouter()

      // This guard is only attached after the app is mounted
      // because otherwise it's also triggered immediately after SSR,
      // causing an unnecessary render measurement
      router.beforeEach((to) => {
        // This is considered to be the beginning of CSR;
        // the end point is considered to be page:finish
        performance.mark(`measure:${getRouteName(to, router, locales)}:start`)
      })
    }
  })

  // This is the end point of both hydration and CSR, we just need to handle the flag
  hook('page:finish', () => {
    if (measureHydration && !hydrateMeasured) {
      measureAction({
        action: 'hydrate',
        routeName: routeName.value
      })
    }

    if (measureCsr && hydrateMeasured) {
      measureAction({
        action: 'render',
        routeName: routeName.value
      })
    }

    hydrateMeasured = true
  })
}

function measureWebVitalsPerformance() {
  if ('onVitalEvent' in window && isFunction(window.onVitalEvent)) {
    window.onVitalEvent((e: WebVitalsEvent) => {
      const { metric } = e

      const metricName = metric.name.toLowerCase()
      const navType = metric.navigationType.replace(/-/g, '_')
      const name = 'web_vitals'
      const labels = {
        metric: metricName,
        nav_type: navType
      }

      const measure: CanvasPerformanceMeasure = {
        name,
        duration: metric.value
      }

      accumulatePerformanceMeasurement(measure, labels)
    })
  }
}

export default defineNuxtPlugin(({ hook }) => {
  const {
    csr: measureCsr,
    ssr: measureSsr,
    webVitals: measureWebVitals,
    hydration: measureHydration
  } = useRuntimeConfig().public.features.prometheus.customMetrics.render

  const router = useRouter()
  const { locales } = useI18nConfig()
  const { enableClientIngest } = useFeatureFlags()
  const routeName = computed(() => getRouteName(router.currentRoute.value, router, locales))
  const routePath = computed(() => getRoutePath(router.currentRoute.value, router, locales))

  if (import.meta.server && measureSsr) measureServerPerformance({ routeName, hook, routePath })
  if (import.meta.client && enableClientIngest) {
    const interval = setInterval(ingestPerformanceMeasures, 500)
    window.addEventListener('beforeunload', () => {
      clearInterval(interval)
      ingestPerformanceMeasures()
    })

    if (measureWebVitals)
      measureWebVitalsPerformance()

    if (measureCsr || measureHydration)
      measureClientPerformance({ routeName, locales, hook, measureCsr, measureHydration })
  }
})
