<template>
  <div class="h-screen">
    <div class="relative h-full">
      <base-picture
        :src="{
          sm: 'https://images.timberland.com/is/image/TimberlandBrand/TBL-403-error-320?wid=320&qlt=50&resMode=sharp2',
          md: 'https://images.timberland.com/is/image/TimberlandBrand/TBL-403-error-768?wid=768&qlt=50&resMode=sharp2',
          lg: 'https://images.timberland.com/is/image/TimberlandBrand/TBL-403-error-1024?wid=1024&qlt=50&resMode=sharp2',
        }"
        alt=""
        class="full"
        fit="cover"
        height="320"
        width="320"
        :props-img="{ class: 'w-full', style: 'object-position: top right', role: 'presentation' }"
      />

      <div class="absolute-0">
        <div class="pt-16 c-white container">
          <h1 class="md:max-w-3/5 display-3">
            {{ $t.maintenance.ERR4xx.title }}
          </h1>
          <p class="pb-12 pt-4 md:(max-w-3/5 pb-8) lg:pt-6">
            {{ $t.maintenance.ERR4xx.message }}
          </p>
          <vf-button size="md" class="<md:w-full" variant="primary-inverse" @click="clearError({ redirect: '/' })">
            {{ $t.maintenance.ERR4xx.cta }}
          </vf-button>
        </div>
      </div>
    </div>
  </div>
</template>
