import type { LocaleCode } from '#types/locale'
import type { Translations } from '#brand/types/i18n'

declare module '#app' {
  interface NuxtApp {
    $locale: LocaleCode
    $seo: Translations['seo']
    $t: Translations['texts']
    $pluralize: (translations: Partial<Record<Intl.LDMLPluralRule, string>>, count: number) => string
    $urls: Translations['urls']
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $locale: LocaleCode
    $seo: Translations['seo']
    $t: Translations['texts']
    $pluralize: (translations: Partial<Record<Intl.LDMLPluralRule, string>>, count: number) => string
    $urls: Translations['urls']
  }
}

export default defineNuxtPlugin({
  name: 'i18n',
  // parallel: true, // disabled until a better fix is found for the issue with translations on the cart store GLOBAL15-76041
  setup: async () => {
    const locale = useLocale()
    const translations = useI18n()
    const { defaultLocale, urlPrefixStrategy } = useI18nConfig()

    if (!translations.value)
      translations.value = await $fetch(`/fapi/i18n/${locale}`)

    if (
      urlPrefixStrategy !== 'no_prefix'
      && (urlPrefixStrategy !== 'prefix_except_default' || locale !== defaultLocale)
    ) {
      const router = useRouter()
      const routes = router.getRoutes()

      routes.forEach((route) => {
        router.addRoute(useLocalisedRouteRecord(route, locale, translations.value.urls))
      })
    }

    // https://tc39.es/ecma402/#pluralrules-objects
    const pluralRules = new Intl.PluralRules(locale)

    return {
      provide: {
        getLocalisedPath: (path: string): string => translations.value.urls[path] || path,
        locale,
        /**
         * https://tc39.es/ecma402/#sec-pluralruleselect
         * `select` function returns  "zero", "one", "two", "few", "many", "other"
         * so object with translation must contain all supported values
         */
        pluralize: (translations: Record<Intl.LDMLPluralRule, string>, count: number) =>
          translations?.[pluralRules.select(count)] || translations.other,
        seo: translations.value.seo,
        t: translations.value.texts,
        urls: translations.value.urls
      }
    }
  }
})
