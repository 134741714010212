<template>
  <NuxtLayout :name="layoutName">
    <error-403 v-if="+error.statusCode === 401 || +error.statusCode === 403" />
    <error-404 v-else-if="error.statusCode.toString().startsWith('4')" />
    <error-500 v-else />
    <app-dialogs />
    <app-dynamic-dialogs />
  </NuxtLayout>
</template>

<script lang="ts" setup>
const props = defineProps<{
  error: {
    statusCode: `${4 | 5}${number}${number}`
  }
}>()

const { brand, isDev } = useRuntimeConfig().public
const { $gtm } = useNuxtApp()
const route = useRoute()
const { hideLayoutOnStatusCode } = useAppConfig().pages.error
const layoutName = hideLayoutOnStatusCode.includes(+props.error.statusCode) ? false : undefined

useHead({
  htmlAttrs: {
    ...(isDev && { brand })
  },
  bodyAttrs: {
    class: 'brand-base'
  }
})

onMounted(async () => {
  $gtm?.push('page.onLoadPageData', route, {
    pageTitle: await getPageTitle(),
    pageCategory: 'Error Page',
    pageName: `${props.error.statusCode} Error`
  })
  $gtm.push('user.onLoadUserData', await getUserEventData())
  $gtm?.push('page.onRouterChange', route)
})
</script>
