// https://digital.vfc.com/wiki/display/ECOM15/Live+Chat
type Interaction = 'Closed' | 'Displayed' | 'Started'

export interface ChatEvent {
  chatID: string
  customerID: string
  chatType: string
  bizRuleName: string
  evtType: string
}

export default {
  onInteraction: (evt: ChatEvent, interaction: Interaction) => ({
    event: 'loadEventData',
    eventCategory: 'LiveChat',
    eventAction: interaction,
    eventLabel: 'Nuance',
    nonInteractive: false,
    customMetrics: {},
    customVariables: {
      chatID: evt.chatID || '',
      customerID: evt.customerID || '',
      chatType: evt.chatType || '',
      bizRuleName: evt.bizRuleName || '',
      evtType: evt.evtType || ''
    },
    _clear: true
  })
}
