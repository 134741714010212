declare module '#app' {
  interface NuxtApp {
    $logRequest: (type: 'server' | 'client', method: string, url: string) => void
  }
}

export default defineNuxtPlugin({
  enforce: 'pre',
  setup: (nuxtApp) => {
    const logs = useState('requestsLogs', () => ({
      server: [],
      client: []
    }))

    nuxtApp.hook('page:finish', () => {
      if (useCookie('outputRequestURLs').value) {
        ['server', 'client'].forEach((type) => {
          console.group(`Page API ${type} requests`)
          logs.value[type].forEach(({ method, url }) => console.log(method, url))
          console.groupEnd()
        })
      }
      logs.value.server = []
      logs.value.client = []
    })

    return {
      provide: {
        logRequest(type, method, url) {
          logs.value[type].push({ method, url })
        }
      }
    }
  }
})
