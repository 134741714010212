const FILE_PATH = '@domains/core/utils/DOM/loadScript.ts'

type ScriptOptions = {
  prepend?: boolean
  type?: 'text/javascript'
}

/**
 * loadScript - Loads scripts without using vue/usehead because vue/usehead not always emit the onload even for onetrust when developer console is open
 * ref: https://digital.vfc.com/jira/browse/GLOBAL15-52262
 */
export const loadScript = (src: string, attrs?: Record<string, any>, options?: ScriptOptions) => {
  return new Promise<{ loaded: boolean, error: boolean, message?: string }>((resolve, reject) => {
    try {
      if (document.querySelector(`script[src="${src}"]`))
        return resolve({ loaded: true, error: false })

      const script = document.createElement('script')

      script.type = options?.type || 'text/javascript'
      script.src = src

      if (attrs) {
        for (const key in attrs)
          script.setAttribute(key, attrs[key])
      }

      script.addEventListener('load', () => {
        resolve({ loaded: true, error: false })
      })

      script.addEventListener('error', () => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          loaded: false,
          error: true,
          message: `Failed to load script with src ${src}`
        })
      })

      if (options?.prepend) {
        const p = document.getElementsByTagName('script')[0]
        p.parentNode!.insertBefore(script, p)
      }
      else {
        document.head.appendChild(script)
      }
    }
    catch (err: any) {
      log.error(`[${FILE_PATH}::loadScript] Error <${err.message}> while loading script <${src}>`)
      reject(err)
    }
  })
}

export const unloadScript = (src: string): Promise<void> => {
  return new Promise((resolve) => {
    const el = document.querySelector(`script[src="${src}"]`)
    let message: string
    if (el) {
      document.head.removeChild(el)
      message = `[${FILE_PATH}::unloadScript] Removed script <${src}>`
    }
    else {
      message = `[${FILE_PATH}::unloadScript] Script <${src}> was not found in document`
    }
    log.info(message)
    resolve()
  })
}
