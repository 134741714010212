type OSType = 'win' | 'mac' | 'x11' | 'linux'

declare module '#app' {
  interface NuxtApp {
    $os?: OSType
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $os?: OSType
  }
}

export default defineNuxtPlugin(async ({ ssrContext }) => {
  const ua = import.meta.client ? navigator.userAgent : ssrContext?.event.node.req.headers['user-agent']
  const os = ['Android', 'Linux', 'Mac', 'Win', 'X11'].filter((el) => ua?.includes(el)).join(':').toLowerCase()
  return {
    provide: {
      os
    }
  }
})
