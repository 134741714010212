import { camelCase } from 'scule'

export default defineNuxtRouteMiddleware((to) => {
  const { brand } = useRuntimeConfig().public
  const { $t, $seo } = useNuxtApp()

  if (!to.name) return

  const name = (to.name as string).split('_').at(0)
  const allMeta = $seo[camelCase(name || '')]

  if (!allMeta) return

  let { 
    sitemap: _, 
    title, 
    description, 
    ...meta 
  } = allMeta
  title = title || $t.htmlTitle
  description = replaceAll(description || $t.htmlDescription, {
    brand: $t.brand,
    brandName: $t.brandName
  })
  
  useSeoMeta({
    title,
    ogTitle: title,
    description,
    ogDescription: description,
    ogImage: `/img/logos/${brand}/default.svg`,
    ...meta,
  })
})
