import authForm from './authForm'
import cart from './cart'
import chat from './chat'
import checkout from './checkout'
import footer from './footer'
import livechat from './livechat'
import loyaltyProgram from './loyaltyProgram'
import newsletterForm from './newsletterForm'
import page from './page'
import pdpPage from './pdpPage'
import plpPage from './plpPage'
import popUp from './popUp'
import product from './product'
import promotion from './promotion'
import shopTheLook from './shopTheLook'
import topNav from './topNav'
import upsell from './upsell'
import user from './user'
import video from './video'

export const events = {
  authForm,
  cart,
  chat,
  checkout,
  footer,
  livechat,
  loyaltyProgram,
  newsletterForm,
  page,
  pdpPage,
  plpPage,
  popUp,
  product,
  promotion,
  shopTheLook,
  topNav,
  upsell,
  user,
  video
}

type ArgumentTypes<K> = K extends (...args: infer A) => any ? A : never

type Generator<Key extends string, Obj extends Record<string, typeof events[keyof typeof events]>> =
  Key extends `${infer DomainName}.${infer EventName}`
    ? Generator<EventName, Obj[DomainName]>
    : Obj[Key]

type Paths<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${Paths<ObjectType[Key]>}`
    : `${Key}`
}[keyof ObjectType & (string | number)]

type FullPaths<Union, Result = ''> = Union extends [infer Key, ...infer Rest]
  ? Key extends `${string}.${string}`
    ? FullPaths<Rest, Result | Key>
    : FullPaths<Rest, Result>
  : Result

type Keys = FullPaths<[Paths<typeof events>]>

export type GTMPush = <K extends Keys>
(event: K, ...payload: ArgumentTypes<Generator<K, typeof events>>) => void
