const PLUGIN_FILE_PATH = '@domains/commerce/plugin/human/index.client.ts'

export default defineNuxtPlugin(async () => {
  const humanScriptPath = `${useOrigin()}/{shortTagId}/init.js`
  const confFilePath = `${useOrigin()}/human/index.js`
  const HUMAN_APP_ID_COOKIE_NAME = 'pxAppID'

  const { enableHumanBotDefenderChallenges } = useFeatureFlags()

  if (!enableHumanBotDefenderChallenges) {
    log.info(`[${PLUGIN_FILE_PATH}] HUMAN no loaded. Feature flag is false`)
    return
  }

  const cookieTagId = useCookie(HUMAN_APP_ID_COOKIE_NAME)

  if (!cookieTagId.value) {
    log.info(`[${PLUGIN_FILE_PATH}] HUMAN no loaded. Missing cookie <${HUMAN_APP_ID_COOKIE_NAME}>`)
    return
  }

  const shortTagId = cookieTagId.value.replace('PX', '')

  try {
    await loadScript(humanScriptPath.replace('{shortTagId}', shortTagId), { async: 1 }, { prepend: true })

    loadScript(confFilePath)
  }
  catch (err: any) {
    log.error(`[${PLUGIN_FILE_PATH}] HUMAN no loaded. Error: ${err.message}`)
  }
})
