export default defineNuxtPlugin(() => {
  const { $locale } = useNuxtApp()
  const { seoData } = useAppConfig()
  const { organizationJsonLd } = seoData[$locale] || {}

  if (organizationJsonLd) {
    const host = useHost()
    useSchemaOrg([
      defineOrganization(organizationJsonLd(host))
    ])
  }
})
