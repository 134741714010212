// https://digital.vfc.com/wiki/display/ECOM15/UDO+-+User+Data+Object
import type { UserContext, UserDataObject } from '#types/gtm'

let auth: ReturnType<typeof useAuthStore>
let profileStore: ReturnType<typeof useProfileStore>

const getClvData = (profile: typeof profileStore.profile) => (profile?.clv_info
  ? {
      'clvInfo-clv': profile.clv_info.clv,
      'clvInfo-monetary': profile.clv_info.monetary,
      'clvInfo-nPurchases': profile.clv_info.n_purch,
      'clvInfo-spent-past': profile.clv_info.spent_past
    }
  : {})

const mapUserDataObject = (context: UserContext) => {
  auth ??= useAuthStore()
  profileStore ??= useProfileStore()

  const baseUser = {
    'guest-id': auth.consumerId,
    'loggedIn': auth.loggedIn,
    ...context
  }

  if (auth.loggedIn) {
    return {
      ...baseUser,
      'consumer-id': auth.consumerNo,
      'loyaltyMember': profileStore.profile?.isLoyaltyMember,
      'vfEmployee': auth.consumerType === 'EMPLOYEE',
      ...getClvData(profileStore.profile)
    } as UserDataObject
  }

  return baseUser as UserDataObject
}

export default {
  onLoadUserData: (context: UserContext) => ({
    event: 'loadUserData',
    user: mapUserDataObject(context),
    _clear: true
  })
}
