import type { PromotionObject } from '#types/gtm'

const baseEvent = {
  eventCategory: 'Enhanced Ecommerce',
  customMetrics: {},
  customVariables: {},
  _clear: true
}

export default {
  // https://digital.vfc.com/wiki/display/ECOM15/a.+promotionImpressions
  onPromotionImpressions: (promotion: PromotionObject) => ({
    ...baseEvent,
    event: 'promotionImpressions',
    eventAction: 'Promotion Impressions',
    nonInteractive: true,
    ecommerce: {
      promoView: {
        promotions: [promotion]
      }
    },
  }),
  // https://digital.vfc.com/wiki/display/ECOM15/b.+promotionClick
  onPromotionClick: (promotion: PromotionObject) => ({
    ...baseEvent,
    event: 'promotionClick',
    eventAction: 'Promotion Click',
    nonInteractive: 0,
    ecommerce: {
      promoClick: {
        promotions: [promotion]
      }
    }
  }),
}
