export const languageConfig = {
  'uk-en': 'en',
  'it-it': 'it',
  'de-de': 'de',
  'es-es': 'es',
  'fr-fr': 'fr',
  'nl-nl': 'nl_NL',
  'pl-pl': 'pl',
  'at-de': 'de',
  'be-nl': 'nl_NL',
  'be-fr': 'fr',
  'be-de': 'de',
  'ch-fr': 'fr',
  'ch-de': 'de',
  'ch-it': 'it',
  'ch-en': 'en',
  'lu-fr': 'fr',
  'lu-de': 'de',
  'lu-nl': 'nl_NL',
  'dk-en': 'en',
  'ie-en': 'en',
  'se-en': 'en',
  'pt-pt': 'pt_PT',
  'us-es': 'es',
}
