export default defineNuxtPlugin({
  parallel: true,
  dependsOn: ['i18n'],
  setup: async () => {
    const { allowSharedCookies } = useAppConfig().api
    const { targetEnv } = useRuntimeConfig().public

    if (allowSharedCookies && targetEnv !== 'PROD')
      clearProductionCookies()

    const auth = useAuthStore()
    await auth.setAccess()
  }
})
