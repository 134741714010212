// https://digital.vfc.com/wiki/display/ECOM15/Forms%3A+Newsletter
import type { FormLocation } from '#types/gtm'

const baseEvent = {
  event: 'loadEventData',
  eventCategory: 'Newsletter',
  eventAction: 'Sign Up',
  _clear: true
}

export default {
  onImpression: (formLocation: FormLocation) => ({
    ...baseEvent,
    eventLabel: 'Impression',
    nonInteractive: true,
    customMetrics: {
      elementView: 1
    },
    customVariables: {
      formLocation
    }
  }),
  onSubmit: (formLocation: FormLocation) => ({
    ...baseEvent,
    eventLabel: 'Submit',
    nonInteractive: false,
    customMetrics: {},
    customVariables: {
      formLocation
    }
  }),
  onConfirmed: (formLocation: FormLocation) => ({
    ...baseEvent,
    eventLabel: 'Confirmed',
    nonInteractive: false,
    customMetrics: {
      elementConversion: 1
    },
    customVariables: {
      formLocation
    }
  })
}
