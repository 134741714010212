export default {
  onAction: (eventAction: string, nonInteractive: boolean = true) => ({
    event: 'loadEventData',
    eventCategory: 'LiveChat',
    eventAction,
    eventLabel: 'Salesforce',
    nonInteractive,
    customMetrics: {},
    customVariables: {},
    _clear: true
  })
}
