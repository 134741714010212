<template>
  <transition name="fade">
    <base-button
      v-if="show"
      class="fixed bottom-0 left-1/2 z-10 mb-12 center rounded-full bg-white p-2 shadow-sm i-flex md:mb-4 -translate-1/2"
      @click="scrollToTop"
    >
      <span class="sr-only">{{ $t.backToTop }}</span>
      <vf-icon name="chevron" size="lg" dir="up" class="hover:c-grey-30" />
    </base-button>
  </transition>
</template>

<script lang="ts" setup>
const { y } = useScroll(document)

const show = computed(() => {
  const { clientHeight, scrollHeight } = document.documentElement
  return scrollHeight / clientHeight > 4 && y.value > clientHeight
})

function scrollToTop() {
  scrollTo({ top: 0, behavior: 'smooth' })
}
</script>
