import type { ChatEvent } from '#commerce/gtm/chat'
import type { GtmPlugin } from '#commerce/plugin/gtm/index.client'
import type { ChatProvider } from '#types/plugin/chat'

const chatListeners = (gtm: GtmPlugin) => ({
  chatListeners: [
    { onChatEngagedEvent: (e: ChatEvent) => gtm.push('chat.onInteraction', e, 'Started') },
    { onChatClosed: (e: ChatEvent) => gtm.push('chat.onInteraction', e, 'Closed') },
    { onChatLaunched: (e: ChatEvent) => gtm.push('chat.onInteraction', e, 'Displayed') }
  ]
})

export default (): ChatProvider => {
  const { $gtm } = useNuxtApp()
  const route = useRoute()
  const router = useRouter()

  const available = ref(false)
  const attempts = 0

  let initiated = false

  const checkAvailability = async () => {
    return new Promise((resolve) => {
      if (available.value) return resolve(true)

      if (window.Inq) {
        available.value = true
        resolve(true)
      }
      else if (attempts >= 2) {
        resolve(false)
      }
      else {
        setTimeout(() => checkAvailability().then(resolve), 500)
      }
    })
  }

  const loadScript = () => {
    const { $script } = useScript({
      src: 'https://vfc.inq.com/chatskins/launch/inqChatLaunch10007246.js',
      async: true,
    }, { trigger: 'onNuxtReady' })

    $script.then(() => {
      checkAvailability()
      // Making the Chat window intractable when the Modal/Panel is opened
      useStyleTag('#nuanMessagingFrame { pointer-events: auto; }')
    })
  }

  const init = () => {
    if (initiated) return

    initiated = true
    window.InqRegistry = chatListeners($gtm)
    loadScript()
  }

  const open = async () => {
    try {
      await checkAvailability()
      const chatContainer = document.getElementById('nuanMessagingFrame')
      if (chatContainer) {
        chatContainer.querySelector('button')?.click()
        chatContainer.setAttribute('tabindex', '0')
      }
    }
    catch (e) {
      console.warn('Nuance Chat failed to load on this page. Please check if it is enabled by the business rules.')
    }
  }

  init()

  watchImmediate(() => route.query, () => {
    if (route.query.chat) {
      open()
      router.replace({ query: { ...route.query, chat: undefined } })
    }
  })

  return {
    provide: {
      chat: {
        open,
        available
      }
    }
  }
}
