// https://digital.vfc.com/wiki/display/ECOM15/PDP+Components

const baseEvent = {
  event: 'loadEventData',
  eventCategory: 'PDP',
  nonInteractive: false,
  customMetrics: {},
  customVariables: {},
  _clear: true
}

export default {
  onImageZoom: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Image Zoom',
    eventLabel
  }),
  onSelectColor: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Color Click',
    eventLabel
  }),
  onSelectSize: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Select Size',
    eventLabel
  }),
  onAddToFavorites: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Add to Favorites',
    eventLabel
  }),
  onRemoveFromFavorites: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Remove from Favorites',
    eventLabel
  }),
  onClickImage: (eventLabel: string | number) => ({
    ...baseEvent,
    eventAction: 'Image Click',
    eventLabel
  }),
  onClickFullImage: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Full Image Click',
    eventLabel
  }),
  onOpenSizeGuide: () => ({
    ...baseEvent,
    eventAction: 'Click on Size Guide',
    eventLabel: undefined
  }),
  onNotifyMeOpen: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Notify Me Click',
    eventLabel
  }),
  onNotifyMeSubmit: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Notify Me Request',
    eventLabel
  }),
  onFindInStore: () => ({
    ...baseEvent,
    eventAction: 'Click on Find in Store',
    eventLabel: undefined
  })
}
