import { useScript } from '@unhead/vue'

declare global {
  interface Window {
    OptanonWrapper: () => void
    OneTrust: {
      ToggleInfoDisplay: () => void
    }
  }
}

declare module '#app' {
  interface NuxtApp {
    $cookieConsent: Ref<ConsentStatus>
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $cookieConsent: Ref<ConsentStatus>
  }
}

export default defineNuxtPlugin({
  name: 'oneTrust',
  parallel: true,
  async setup({ provide }) {
    const route = useRoute()
    const router = useRouter()
    const { isPreview } = useCms()
    const { configGtm: { onetrust_id } } = useFeatureFlags()

    // OneTrust script should not be loaded in preview mode
    // Content preview is shown in iframe and this script could not set 3rd party cookies
    // Without this restriction user have to accept cookies infinitely

    if (!onetrust_id || isPreview) return

    const cookieConsent = ref(getCookieConsent())

    const { OptanonWrapper } = window
    window.OptanonWrapper = function () {
      OptanonWrapper?.()

      cookieConsent.value = getCookieConsent()
    }

    useScript({
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      ['data-domain-script' as any]: onetrust_id,
      ['data-language' as any]: useLocale()
    })

    useStyleTag('#onetrust-banner-sdk { pointer-events: auto; }')

    /**
     * Cookie settings hash is used to open cookie settings modal
     * This approach is used because it's not possible to specify link ID in the RichText Editor and use script binding by ID
     *
     * Recommended usage per OneTrust documentation:
     * <a id="ot-sdk-btn">Cookie Settings</a>
     * Our implementation:
     * <a href="#TrustTool">Cookie Settings</a>
     */
    const cookieSettingsHash = '#TrustTool'

    watch(() => route.hash, () => {
      if (route.hash === cookieSettingsHash) {
        router.replace({ hash: undefined })
        window.OneTrust.ToggleInfoDisplay()
      }
    })

    provide('cookieConsent', cookieConsent)
  }
})
