import { generateApiMockConfig } from '#root/api/util/mocking'

export default defineNuxtPlugin(async () => {
  // Normally the API store that contains the mock configurations is only
  // initialised and installed when an API call is being executed.
  // This means that we can only use the query string to override them on such pages.
  // The call below allows us to use any page to start mocking, as the config will then be persisted
  if (useRuntimeConfig().public.targetEnv !== 'PROD')
    generateApiMockConfig()
})
