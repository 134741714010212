// global nuxt error handler that uses our logger util to format
// nuxt errors errors as json for dynatrace and monitoring services

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  function handleErrorMessage(err: any) {
    const formattedTitle = `Application Error: ${err.statusCode || 'unknown status code'}`

    if (config.public.isDev)
      console.error(formattedTitle, err)

    const path = err.data?.path
    const host = err.data?.host
    const queryParams = err.data?.params
    log.error(formattedTitle, { stackTrace: err.stack || err, api: { path, host, queryParams } })
  }

  nuxtApp.hook('app:error', handleErrorMessage)
  nuxtApp.vueApp.config.errorHandler = handleErrorMessage
})
