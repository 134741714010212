/**
 * Replaces all placeholders of the type {{ name }} in the input with the provided value
 *
 * @param input The original string value to do the replacement in
 * @param name The name of the variable being replaced
 * @param value The value the variable is being replaced with
 *
 * @returns The resulting interpolated string
 */
const replacePlaceholder = (input: string, name: string, value: string) => {
  const pattern = new RegExp(`{{\\s*${name}\\s*}}`, 'g')

  return input.replace(pattern, value)
}

/**
 * Returns a new object or string depending on the provided input with
 * placeholders of the type {{ variable_name }} with the provided values
 * according to the replacements object
 *
 * @category Utils
 *
 * @example
 * replacePlaceholders({
 *   key: {{ value }}_suffix
 * }, {
 *   value: 'some_value'
 * })
 *
 * returns { key: 'some_value_suffix' }
 *
 * @example
 * replacePlaceholders(
 *  'value 1: {{ value1 }}; value 2: {{value2}}',
 *   {
 *     value1: 'First Value',
 *     value2: 'Second Value'
 *   }
 * )
 *
 * returns 'value 1: First Value; value 2: Second Value'
 */
export const replacePlaceholders = (input, replacements: Record<string, string>) => {
  if (!input || !replacements) return input
  const isString = typeof input === 'string'
  const template = isString ? input : JSON.stringify(input)

  const result = Object.entries(replacements).reduce(
    (acc, [varName, varValue]) => replacePlaceholder(acc, varName, varValue),
    template
  )

  if (!isString)
    return JSON.parse(result)

  return result
}
