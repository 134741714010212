import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { camelCase } from 'scule'

import type { PageCategoryType, PageContext } from '#types/gtm'

// Example: Given a page name such as "/about-us" It will return "About Us"
const getHumanizedPageName = (string: string) => string.split('_')[0].replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())

const getPageInfo = ({ name, path }, pageData: PageContext): [category?: PageCategoryType, ...payload: any[]] => {
  // Use $seo i18n to fetch pageName, pageNameEng, pageCategory
  // If these properties (or some of them) are not declared they will be filled according to the following example:
  // /about-us  -> pageName:      'About Us' (language dependent, last path segment)
  // /about-us  -> pageNameEng:   'About Us' (last english segment of the sitemap.path value)
  //            -> pageCategory:  'Content' (default value for custom content page)

  const { $seo } = useNuxtApp()
  const seo = $seo[camelCase(name.split('_')[0])] || {}

  const locale = useLocale()
  const routeName = name.replace(new RegExp(`_${locale}$`), '')

  const [dynamicPageCategory, dynamicPageName] = {
    'c-slug': ['PLP', getPageBreadcrumb(pageData.breadcrumbs)?.join(' - ')],
    'p-slug': ['PDP', getUrlId(path, 'Product')],
    'search': ['Search', pageData.searchType]
  }[routeName] || []

  return [
    pageData.pageCategory || seo.category || dynamicPageCategory || 'Content',
    pageData.pageName || seo.name || dynamicPageName || getHumanizedPageName(path?.split('/')?.pop()),
    pageData.pageNameEng || seo.nameEng || getHumanizedPageName(seo?.sitemap?.path?.split('/')?.pop() || '')
  ]
}

export default {
  onLoadPageData: (route: RouteLocationNormalizedLoaded, pageContext: PageContext = {}) => {
    const { currency } = useRuntimeConfig().public
    const locale = useLocale()
    const [pageCategory, pageName, pageNameEng] = getPageInfo(route, pageContext)
    const { suggestedPhrase } = history.state
    const formatPageName = (category, name) =>
      `${(category && name) && category !== name
        ? `${category}: ${name}`
        : category || name}`

    return {
      event: 'loadPageData',
      page: {
        addlBreadcrumbInfo: pageContext.breadcrumbs?.map(({ label, url }) => ({
          label,
          url,
          id: url?.split('-').pop()
        })),
        brand: useSiteName(),
        breadcrumb: getPageBreadcrumb(pageContext.breadcrumbs)?.join('|').toLowerCase(),
        categoryID: pageContext.categoryId,
        countryCode: useCountryCode(),
        countryLanguage: useLanguageCode().toLocaleLowerCase(),
        currencyCode: currency[locale],
        ...(pageCategory && { pageCategory }),
        ...((pageCategory && pageName) && {
          pageName: formatPageName(pageCategory, pageName)
        }),
        pageNameEng: formatPageName(pageCategory, pageNameEng),
        pageTitle: pageContext.pageTitle || document?.title,
        searchResults: pageContext.searchResults,
        searchTerm: pageContext.searchTerm || suggestedPhrase,
        searchTermAdj: pageContext.searchTermAdj,
        searchType: suggestedPhrase ? 'click' : pageContext.searchType,
        sourceId: pageContext.sourceId,
      },
      _clear: true
    }
  },
  onRouterChange: ({ fullPath, name }: RouteLocationNormalizedLoaded) => {
    return {
      'event': 'routerChange',
      'content-name': fullPath,
      'content-view-name': name
    }
  }
}
