import type { CountryCode, LocaleCode } from '#types/locale'
import type { NetlifyCountry, SelectorSite } from '#types/locationSelector'

const getRedirectLink = (site: SelectorSite, sitemaps: LocaleCode[]) => {
  if (site.canvasDomain && sitemaps.includes(site.locales[0].code)) {
    const host = useSubdomain() ? site.canvasDomain.replace('{subdomain}', useSubdomain()) : useHost()
    return `${host}/${site.locales[0].code}`
  }
  return `${site.domain}/${site.locales[0].path}`
}

export default defineNuxtPlugin(() => {
  (async () => {
    // configStaticPagesSitemaps is a FF originally created to handle the static content sitemap
    // but esentially it returns the locales app and running for a given brand on Canvas.
    // To avoid FF duplications it can be used here despite the name of the flag can be
    // slightly misleading.
    const { configStaticPagesSitemaps, enableLocationConfirmation } = useFeatureFlags()

    // IF FF is disabled
    // OR if is in preview mode
    // OR if is a preview of a build
    // OR if is a location selector page
    if (
      !enableLocationConfirmation
      || useRouteQuery('preview').value
      || useHost().includes('netlify.app')
      || useRoute().path.endsWith(useLocalisedRoute('/location-selector'))
    )
      return

    const appCountryCode = useCountryCode()
    const confirmedCountry = useLocalStorage<Uppercase<CountryCode>>('confirmedCountry', null)

    if (confirmedCountry.value === appCountryCode) return

    const [userCountry, brandSites]: [NetlifyCountry['country'], SelectorSite[]] = await Promise.all([
      $fetch<NetlifyCountry>('https://canvas-edge-functions.netlify.app/country').then((data) => data.country),
      $fetch<any>('/fapi/pages/location-selector').then((sites) => sites)
    ])

    // if country is not provided by Netlify
    // if country provided by Netlify matches with app current country
    if (!userCountry?.code || appCountryCode === userCountry.code) return

    const [lang] = navigator.language.split('-')
    const userCountryCode = userCountry.code.toLowerCase()
    const userLocale = `${lang}-${userCountryCode}` as LocaleCode
    const appLocale = useLocale()

    if (appLocale === userLocale) return

    const expectedSite = brandSites.find((site) => site.countryCode === userCountry.code)
    const actualSite = brandSites.find((site) => site.countryCode === appCountryCode)

    if (!expectedSite || !actualSite) return

    const { ModalLocationConfirmation } = useDialogsStore()

    ModalLocationConfirmation.open({
      currentCountryName: actualSite.name,
      currentDomain: useTld(),
      link: getRedirectLink(expectedSite, configStaticPagesSitemaps),
      expectedCountry: expectedSite.countryCode,
    })
  })()
})
