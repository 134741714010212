/**
 * FeatureFlags Nuxt Plugin
 * Defines a Nuxt plugin for retrieving feature flags
 * Merges local flags overrides with LD flags
 * @returns {FeatureFlags} - feature flag list
 * @example
 * const { sampleFeatureFlag } = useFeatureFlags()
 * @category Plugin
 */

import type { FeatureFlags } from '#core/server/types/featureFlags'

declare module '#app' {
  interface NuxtApp {
    $feature: FeatureFlags
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $feature: FeatureFlags
  }
}

export default defineNuxtPlugin({
  enforce: 'pre',
  async setup({ hook }) {
    const { launchDarklyClientKey } = useRuntimeConfig().public
    const featureFlags = useFeatureFlags()
    const route = useRoute()

    const queryParameter = ref(route.query.ldPassthrough?.toString() ? route.query.ldPassthrough?.toString() : '')
    const ldPassthrough = useFeatureFlagsPassthroughHeader().value || queryParameter.value
    const settings: any = useApiHeaders() || {}

    const headers = {
      ...settings,
      locale: useLocale(),
      ...(ldPassthrough ? { 'x-ld-passthrough': ldPassthrough } : {})
    }

    // The server plugin will not have run on CSR-only pages,
    // and we'll only have the default empty object state,
    // we need to fetch the flags from the Nitro endpoint via an ajax call
    if (!Object.keys(featureFlags).length)
      Object.assign(featureFlags, await $fetch('/fapi/featureFlags', { headers }))

    if (featureFlags.useLdClientSdk) {
      try {
        hook('app:mounted', async () => {
          // Imported at runtime so it gets split into its own chunk
          // and is only loaded when needed
          const { initialize } = await import('launchdarkly-js-client-sdk')

          const localFlags = useLocalFeatureFlags()
          const client = initialize(
            launchDarklyClientKey,
            getLDContext(headers),
            { streaming: true }
          )

          await client.waitForInitialization()

          // Reevaluate all flags on CSR.
          // Listening for changes in real time as intended
          // can have a negative effect on performance
          // due to the extensive usage of lazy hydration.
          // This is a compromise solution to get the client SDK in use
          // until a better solution can be found for making the real-time
          // flag evaluation work well with the app's use of lazy hydration
          useRouter().afterEach(async () => {
            Object.assign(featureFlags, { ...client.allFlags(), ...localFlags.value })
          })
        })
      }
      catch (err: any) {
        log.error('[Canvas:LD] Retrieve of the feature flags failed, using state', err)
      }
    }

    return {
      provide: {
        feature: featureFlags
      }
    }
  }
})
